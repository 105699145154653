<template>
    <div>
<!--        <v-container class="hidden-lg-and-up">-->
<!--            <v-layout row wrap class="text-xs-center ">-->
<!--                <v-flex xs10 offset-xs1>-->
<!--                    <h1 v-html="lang['homeTitle']"></h1>-->
<!--                    <p class="homeSpecial" v-html="lang['homeSpecial']"></p> <br>-->
<!--                    <p v-html="lang['homeAfter']"></p> <br>-->
<!--                    <p v-html="lang['homeMain']"></p>-->

<!--                </v-flex>-->
<!--            </v-layout>-->
<!--        </v-container>-->
        <!--
        mobile on top
        ------------------------------------------------------------------------------------------------------
        desktop below
        -->
        <v-container>
            <v-layout row wrap class="text-xs-center ">
                <v-flex xs12 >
                    <p>
                    <h2 v-html="lang['homeTitle']"></h2>
                    <p class="homeSpecial" v-html="lang['homeSpecial']"></p> <br>
                    <p>
                        Die Gesundheits- und Massagepraxis in Einsiedeln <br>
                        <v-img height="150px" contain :src="require('@/assets/stone_home.jpg')"></v-img>
                        orandum est ut sit mens sana in corpore sano <br>
                        es ist zu bitten, dass ein gesunder Geist in einem gesunden Körper sei
                    </p> <br>
                    <p v-html="lang['homeMain']" class="text-xs-left pl-2"></p>

                </v-flex>
            </v-layout>
        </v-container>

    </div>
</template>

<script>
    import router from '../router.js'
    import store from '../store.js'

    export default {
        components: {
        },
        methods: {
            navPage (page) {
                router.push({path: page});
            },
            homeTrue () {
                store.dispatch('homeTrue')
            }
        },
        computed: {
            home () {
                return this.$store.getters.home
            },
            lang() {
                let HomeLangs = {};
                this.$store.getters.text.forEach(function(langObj){
                    if (langObj.target === '/') {
                        //set the name to the value. if a name already exits it gets overwritten.
                        HomeLangs[langObj.name] = langObj.value
                    }
                });
                return HomeLangs
            }
        },
        beforeCreate () {
            store.dispatch('footerFalse');
            store.dispatch('homeFalse')
        },
        metaInfo () {
            return {
                content: 'Die Gesundheits- und Massagepraxis in Einsiedeln. ' +
                    'Chicasa steht für das Chi, die positive Lebensenergie und den Energiefluss ' +
                    'und Casa, das Haus bzw. der Körper, in welchem diese Energie fliesst. '
            }
        }
  }
</script>
